import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, map } from 'rxjs';

import {
  portalLead,
  portalOptionSelected,
  portalSubmitPassengers,
  portalSetHiddenFlightsPresented,
  portalSubmitOption,
  portalUpdateOption,
  sendPasswordToEmail,
  portalFinishPayment,
} from '@app/services/api/api.graphql';
import {
  PortalPassengerNodeInput,
  PortalLeadNode,
  PortalOrderNode,
  PortalOrderStatusType,
  PortalCreditCardNodeInput,
  PortalPassengerNode,
} from '@app/services/api/api.types';
import { Option } from '@app/forms/formly/formly-utils';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private httpClient: HttpClient) {}

  portalLead(variables: { portalLink: string }) {
    const query = portalLead();

    return this.httpClient
      .post<{ data: { portalLead: PortalLeadNode }; errors: Error[] }>(baseUrl, {
        query,
        variables,
      })
      .pipe(
        map((response) => ({
          portalLead: response.data.portalLead,
          errors: response.errors,
        })),
      );
  }

  portalUpdateOption(variables: {
    inputUpdate: {
      portalLink: string;
      status: PortalOrderStatusType;
    };
  }) {
    const query = portalUpdateOption();

    return this.httpClient
      .post<{ data: { portalUpdateOption: { ok: boolean } } }>(baseUrl, { query, variables })
      .pipe(map((response) => response.data.portalUpdateOption));
  }

  portalSubmitOption(variables: {
    input: {
      portalLink: string;
      passengers: PortalPassengerNodeInput[];
      creditCard?: PortalCreditCardNodeInput;
      tipsAmount?: number;
      disruptionProtection?: boolean;
      cancelForAnyReason?: boolean;
      clientBalanceAmount?: number;
      priceDropProtection?: boolean;
      anonymous?: boolean;
      payByStripe?: boolean;
      saveCardToStripe?: boolean;
      stripeCreditCardId?: string;
    };
  }) {
    const query = portalSubmitOption();

    return this.httpClient
      .post<{
        data: { portalSubmitOption: { ok: boolean; errors: string; stripeClientSecret: string } };
      }>(cloudfrontApi, {
        query,
        variables,
      })
      .pipe(map((response) => response.data.portalSubmitOption));
  }

  countries() {
    return from(import('static/countries').then((mod) => mod.default as unknown as Option[])).pipe(
      map((options) => [...options]),
    );
  }

  states() {
    return from(import('static/states').then((mod) => mod.default as unknown as Option[])).pipe(
      map((options) => [...options]),
    );
  }

  portalSubmitPassengers(variables: {
    input: { portalLink: string; passengers: PortalPassengerNodeInput[] };
  }) {
    const query = portalSubmitPassengers();

    return this.httpClient
      .post<{
        data: {
          portalSubmitPassengers: {
            ok: boolean;
            errors: string;
            passengers: PortalPassengerNode[];
          };
        };
      }>(baseUrl, {
        query,
        variables,
      })
      .pipe(map((response) => response.data.portalSubmitPassengers));
  }

  portalSetHiddenFlightsPresented(variables: { portalLink: string }) {
    const query = portalSetHiddenFlightsPresented();

    return this.httpClient
      .post<{
        data: { portalSetHiddenFlightsPresented: { ok: boolean; result: PortalOrderNode } };
      }>(baseUrl, { query, variables })
      .pipe(map((response) => response.data.portalSetHiddenFlightsPresented));
  }

  sendPasswordToEmail(variables: { portalLink: string; email: string }) {
    const query = sendPasswordToEmail();

    return this.httpClient.post<{
      data: { sendPasswordToEmail: { ok: boolean } };
      errors: Error[];
    }>(baseUrl, { query, variables });
  }

  portalOptionSelected(variables: { portalLink: string }) {
    const query = portalOptionSelected();

    return this.httpClient.post<{
      data: { portalOptionSelected: { ok: boolean } };
    }>(baseUrl, { query, variables });
  }

  portalFinishPayment(variables: { portalLink: string }) {
    const query = portalFinishPayment();

    return this.httpClient.post<{
      data: { portalFinishPayment: { ok: boolean } };
    }>(baseUrl, { query, variables });
  }
}

const baseUrl = environment.GRAPHQL_URL;
const cloudfrontApi = environment.CLOUDFRONT_API as string;
